/* App.css */

/* Center the entire content vertically and horizontally */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #69b2fb;
}

/* Set a background color for the body */
body {
  margin: 0;
  background-color: #69b2fb;
}

h3 {
  columns: #000000;
  font-size: 16px;
}

/* Style the header */
.App-header {
  background-color: #007BFF;
  padding: 20px;
  text-align: center;
  width: 90%; /* Set width to 90% */
  margin: 0 auto; /* Center horizontally */
}

/* Style the header's h1 and p */
.App-header h1 {
  color: #FFFFFF;
  margin: 0;
}

.App-header p {
  color: #FFFFFF;
}

/* Style the main content */
main {
  text-align: center;
  width: 90%; /* Set width to 90% */
  margin: 0 auto; /* Center horizontally */
}

/* Style the user input container */
.user-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%; /* Full width within parent container */
}

/* Style each input group */
.input-group {
  display: flex;
  flex-direction: row; /* Arrange label and input side by side */
  align-items: center; /* Vertically center label and input */
  margin-bottom: 10px;
}

/* Style the labels */
.input-group label {
  font-weight: bold;
  margin-right: 10px; /* Add some spacing between label and input */
  width: 200px; /* Set a consistent width for the labels */
  text-align: left; /* Align label text to the right */
}

/* Style the input fields */
.input-group input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit; /* Inherit the font from the parent element */
  font-size: inherit; /* Inherit the font size from the parent element */  
}

.input-group textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit; /* Inherit the font from the parent element */
  font-size: inherit; /* Inherit the font size from the parent element */
}


/* Style the submit button */
.user-input button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.user-input button:hover {
  background-color: #0056b3;
}

/* Style the story cards */
.story-card {
  background-color: #FFFFFF;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin: 20px 0;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: var(--font-size-small);
}

/* Define different font sizes */
.font-size-small {
  --font-size-small: 16px;
}

.font-size-medium {
  --font-size-small: 20px;
}

.font-size-large {
  --font-size-small: 28px;
}

.font-size-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.font-size-buttons button {
  margin-right: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  color: black;
}

.font-size-buttons button:hover {
  text-decoration: underline;
}


/* Style the footer */
footer {
  padding: 10px;
  background-color: #007BFF;
  text-align: center;
  width: 90%; /* Set width to 90% */
  margin: 0 auto; /* Center horizontally */
}

/* Style the footer text */
footer p {
  color: #FFFFFF;
  margin: 0;
}

/* Style for the audio player and button */
.audio-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.audio-player audio {
  width: 100%;
  max-width: 300px;
}

.audio-player button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.audio-player button:hover {
  background-color: #0056b3;
}

/* add spinner */
/* Define the spinning animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Apply the spinner styles */
.button-content {
  display: flex;
  align-items: center;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-top: 2px solid #007BFF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 5px;
}


.loading-text {
  margin-left: 5px; /* Add spacing between spinner and text */
}

/* CustomLogin.css */
.custom-login-container {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Position the logout button */
.logout-button {
  position: relative;
  top: 10px;
  right: 0px;
}

/* Style the MainContent div */
.MainContent {
  display: flex; /* Use flexbox to arrange the LeftMenu and MainContentRight side by side */
  width: 100%; /* Set width to 100% to take up the full width of the parent container */
}

/* Style the LeftMenu */
.LeftMenu {
  width: 50px; /* Set the width for the left menu (adjust as needed) */
  background-color: #1c8dff; /* Set background color for the left menu */
  color: #fff; /* Set text color */
}

/* Style the MainContentRight */
.MainContentRight {
  flex-grow: 1; /* Allow the main content to grow and take up the remaining space */
  padding: 20px 20px;
  background-color: #fff; /* Set background color for the main content */
  color: #333; /* Set text color */
}

/* Add styles for the LeftMenu component */
.left-menu {
  width: 100%; /* Full width within the left menu container */
  background-color: #1c8dff;
  color: white;
  padding: 5px;
  min-height: 100vh; /* Make the left menu full height */
}

.left-menu ul {
  list-style-type: none;
  padding: 0;
}

.left-menu li {
  margin-bottom: 10px;
  cursor: pointer;
}

.left-menu li:hover {
  text-decoration: underline;
}

.hamburger-icon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  cursor: pointer;
  background: none;
  border: none;
}

.menu {
  position: fixed;
  top: 40px;
  left: -200px; /* Hide the menu initially by moving it off-screen */
  width: 150px; /* Adjust the width as needed */
  height: 200px;
  border-radius: 15px;
  background-color: #FFF; /* Background color of the menu */
  z-index: 1;
  transition: left 0.3s ease; /* Add a smooth transition effect when showing/hiding */
}

/* Show the menu when 'menuVisible' is true */
.menu.visible {
  left: 0;
}

.link-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: blue; /* Change the color to your link color */
  text-decoration: none;
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
  transition: font-weight 0.3s;
}

.link-button:hover {
  font-weight: bold; /* Change font-weight to bold on hover */
}

/* Style for the <ul> element */
ul {
  list-style: none; /* Remove default list bullets */
  padding: 10px; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

/* Style for the <li> elements */
li {
  margin-bottom: 15px; /* Adjust margin between list items */
  text-align: left;
}